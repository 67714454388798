<template>
  <div class="content-layout-fixer">
    <div v-if="isVisibleFinalQuestion">
      <VueText class="final-quiz-header" weightLevel="3" color="grey-30">Final Quiz</VueText>
      <VueWrapperNarrow class="quiz-wrapper" v-if="isQuizStarted && quizData.nextQuestion">
        <img
          v-if="!!quizData.nextQuestion.image"
          class="quiz-img"
          :src="quizData.nextQuestion.image"
          alt="quiz image"
        />
        <div class="quiz">
          <div class="question">
            {{ quizData.nextQuestion.question }}
            <span>{{ quizData.nextQuestion.priorty + '/' + quizData.questionCount }}</span>
          </div>
          <VueListView dir="column" class="option-list">
            <VueButton
              class="option"
              v-for="o in quizData.nextQuestion.options"
              :key="o.optionId"
              :isSingleLine="false"
              outlined
              :class="optionClass(o.optionId)"
              @click.prevent="() => selectOption(o.optionId)"
              >{{ o.option }}</VueButton
            >
          </VueListView>
        </div>
        <BrandButton
          @click="onSubmit()"
          class="btn-answer"
          type="submit"
          :disabled="!selectedAnswer"
          >{{
            !submitedAnswer ? 'CEVAPLA' : quizData.isLastQuestion ? 'BİTİR' : 'SIRADAKİ SORUYA GEÇ'
          }}</BrandButton
        >
      </VueWrapperNarrow>
    </div>
    <div v-else class="wrapper">
      <VueWrapperNarrow class="card-step-bar">
        <div class="step-bar">
          <div
            v-for="index in currentCategory.totalStep"
            @click="handleClick(index)"
            :class="getStepClasses(index)"
            :key="index"
          />
        </div>
      </VueWrapperNarrow>
      <div v-if="takeFinalExamText" @click="goToFinalQuiz" class="take-final-exam">
        <VueIcon
          class="icon-info"
          :width="icons.info.width"
          :height="icons.info.height"
          :iconName="icons.info.name"
          iconColor="#c60000"
        />
        <VueText style="margin: 5px 15px; font-size: 18px;  color:#c60000" weightLevel="1"
          >Final Sınavına Giriniz!</VueText
        >
      </div>
      <div class="video-banner">
        <swiper
          v-if="!isQuizStarted && slideVideoList"
          @slideChange="changeSwiperIndex"
          :options="sliderOptions"
          ref="swiperId"
        >
          <swiper-slide :key="video.id" v-for="video in slideVideoList">
            <div class="video-wrapper">
              <BrandVimeoPlayer
                ref="vimeoPlayer"
                class="video-wrapper"
                @timeUpdate="onProgress($event)"
                @end="onProgress($event, true)"
                :logger="{ interval: 5 }"
                :id="video.id"
                :data="{
                  playbackPosition: video.log && video.log.stopTime,
                  percentWatched: video.log && video.log.percent,
                  isViewed: video.log && video.log.viewCount > 0,
                  viewStatus: video.log && video.log.status,
                  embedVideo: video.embedVideo,
                  videoId: video.id,
                  title: video.title,
                }"
              />
            </div>
          </swiper-slide>
          <div class="swiper-button-next" slot="button-next" />
          <div class="swiper-button-prev" slot="button-prev" />
        </swiper>
        <div :class="[{ active: nextButtonVisibilty }, 'quiz-arrow-btn']" @click="startQuiz()" />
        <div v-if="!isQuizStarted && currentCategory.htmlText" class="description-wrapper">
          <VueText
            sizeLevel="6"
            weightLevel="3"
            class="description"
            v-html="currentCategory.htmlText"
          />
        </div>
        <VueWrapperNarrow class="quiz-wrapper" v-if="isQuizStarted && quizData.nextQuestion">
          <img
            v-if="!!quizData.nextQuestion.image"
            :src="quizData.nextQuestion.image"
            class="quiz-img"
            alt="quiz image"
          />
          <div class="quiz">
            <div class="question">
              {{ quizData.nextQuestion.question }}
              <span>{{ quizData.nextQuestion.priorty + '/' + quizData.questionCount }}</span>
            </div>
            <VueListView dir="column" class="option-list">
              <VueButton
                class="option"
                v-for="o in quizData.nextQuestion.options"
                :key="o.optionId"
                :isSingleLine="false"
                outlined
                :class="optionClass(o.optionId)"
                @click.prevent="() => selectOption(o.optionId)"
                >{{ o.option }}</VueButton
              >
            </VueListView>
          </div>
          <BrandButton
            @click="onSubmit()"
            class="btn-answer"
            type="submit"
            :disabled="!selectedAnswer"
            >{{
              !submitedAnswer
                ? 'CEVAPLA'
                : quizData.isLastQuestion
                ? 'BİTİR'
                : 'SIRADAKİ SORUYA GEÇ'
            }}</BrandButton
          >
        </VueWrapperNarrow>
      </div>
      <div class="other-videos">
        <VueHeadline level="1" class="headline">{{ currentCategory.name }}</VueHeadline>
        <div class="video-card-wrapper">
          <div
            v-for="(video, key) in academyVideoList"
            @click="toSlide(key)"
            :class="video.class"
            :key="key"
            class="card"
          >
            <div class="card-inner">
              <img class="card-img" :src="video.thumbWithoutPlayButton" :alt="video.title" />
              <div class="card-content">
                <VueHeadline level="4" class="card-title">{{ video.title }}</VueHeadline>
                <VueText sizeLevel="4" class="card-desc">{{ video.description }}</VueText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BrandInfoPopup
      style="backdrop-filter: blur(1px) !important;"
      :wrapperContentPadding="'0'"
      :show.sync="isVisibleModulePopup"
    >
      <img
        @click="goToNextModule"
        :src="getAcademyPopupImage"
        alt="module-info"
        style="cursor: pointer;"
      />
    </BrandInfoPopup>
  </div>
</template>

<script>
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import BrandInfoPopup from '@/components/brand/Modals/BrandModal/BrandInfoPopup.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import { Entrepreneurship } from '@/services/Api/index';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import vimeoUtils from '@/mixins/vimeoUtils.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';

export default {
  name: 'Entrepreneurship',
  components: {
    VueHeadline,
    VueText,
    swiper,
    VueIcon,
    swiperSlide,
    BrandButton,
    VueListView,
    VueButton,
    VueWrapperNarrow,
    BrandVimeoPlayer,
    BrandInfoPopup,
  },
  mixins: [vimeoUtils],
  data() {
    return {
      isVisibleModulePopup: false,
      isVisibleFinalQuestion: false,
      finalQuizModel: {},
      academyPopupImage: '',
      moduleData: {},
      currentCategory: {},
      slideVideoList: [],
      videoList: [],
      academyVideoList: [],
      activeSlideIndex: 0,
      quizData: {},
      finalQuizId: null,
      nextButtonVisibilty: false,
      isQuizStarted: false,
      isSuccessCertificate: false,
      answerResponse: null,
      selectedAnswer: null,
      submitedAnswer: false,
      totalViewingPercent: 0,
      takeFinalExamText: false,
      sliderOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        resistance: true,
        resistanceRatio: 0.7,
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        onSlideChangeEnd: function() {
          this.onSwipe();
        },
      },
    };
  },
  computed: {
    getAcademyPopupImage() {
      return this.academyPopupImage ?? '';
    },
    icons() {
      return ICON_VARIABLES;
    },
  },
  async created() {
    if (this.$route.query.id) {
      await this.setAcademyDetail(this.$route.query.id);
      await Entrepreneurship.checkFinalQuiz(this.$route.query.parentId).then(res => {
        if (!res?.data?.Data) return;
        this.finishQuizModel = res?.data?.Data;
        this.finalQuizId = res?.data?.Data.finalQuizId;
        this.isSuccessCertificate = res.data.Data.isSuccessCertificate;
        this.takeFinalExamText = !this.isSuccessCertificate && !!this.finalQuizId;
      });

      this.$store.dispatch('app/setOverlayFunction', false);
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.id) return;
      this.setAcademyDetail(this.$route.query.id);
    },
  },
  methods: {
    goToFinalQuiz() {
      this.isVisibleFinalQuestion = true;
      this.startQuiz();
    },
    goToNextModule() {
      this.isVisibleModulePopup = false;

      if (this.finishQuizModel.finalQuizId && this.finishQuizModel.nextModuleId) {
        this.startQuiz();
        this.isVisibleFinalQuestion = true;
      } else if (
        !this.finishQuizModel.finalQuizId &&
        this.finishQuizModel.isSuccessCertificate &&
        this.finishQuizModel.nextModuleId === 0
      ) {
        this.$router.push({ path: `${RoutesRoot.Secure.path}/${RoutesSecure.Certificates.path}` });
      } else if (!this.finishQuizModel.finalQuizId && !this.finishQuizModel.isSuccessCertificate) {
        const path = `${RoutesRoot.Secure.path}/${RoutesSecure.Entrepreneurship.path}`;

        const query =
          this.finishQuizModel.nextModuleId !== 0
            ? { parentId: this.$route.query.parentId, id: this.finishQuizModel.nextModuleId }
            : null;

        this.$router.push({ path, query });
        window.location.reload();
      } else if (
        this.finishQuizModel.finalQuizId &&
        !this.finishQuizModel.isSuccessCertificate &&
        this.finishQuizModel.nextModuleId === 0
      ) {
        this.$router.push({
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}`,
          query: { type: 'Girişimcilik Akademi' },
        });
      }
    },
    handleClick(index) {
      const { completedStep, totalStep } = this.currentCategory;

      const isCompletedStep = completedStep >= index;
      const isCurrentSlide = this.activeSlideIndex === index - 1;

      if (isCompletedStep && index === totalStep) {
        this.startQuiz();
      } else if (isCompletedStep && !isCurrentSlide && index !== totalStep) {
        this.toSlide(index - 1);
      }
    },
    getStepClasses(index) {
      const completedStepCondition = this.currentCategory.completedStep >= index;
      const currentCondition = this.activeSlideIndex === index - 1;

      return {
        active: completedStepCondition,
        current: currentCondition,
        step: true,
      };
    },
    setAcademyDetail(id, endFlag = false) {
      Entrepreneurship.getAcademyDetail(Number(id)).then(res => {
        if (!res?.data?.Data?.detail) return;

        this.currentCategory = res.data.Data.detail.currentCategory;

        if (!endFlag) {
          this.moduleData = res.data.Data.detail;
          this.setCurrentState();
        }
      });
    },
    async setCurrentState(isEnded) {
      let index = this.moduleData.videos.findIndex(i => !i.log || i.log.status === '1');
      this.videoList = this.moduleData.videos.map((i, j) => {
        return {
          ...i,
          class: index !== -1 && j > index ? 'passive' : '',
        };
      });

      this.academyVideoList = await this.getVideoThumbsAsync(this.videoList);

      if (
        this.currentCategory.completedStep === this.currentCategory.totalStep &&
        !isEnded &&
        !this.isQuizStarted
      ) {
        this.slideVideoList = this.videoList;
        this.$refs.swiperId.swiper.slideTo(0, 0);
      } else if (isEnded) {
        this.setAcademyDetail(this.$route.query.id, true);
        if (index !== -1) {
          this.slideVideoList = this.videoList.slice(0, index + 1);
        }
        if (this.activeSlideIndex === this.videoList.length - 1) {
          this.nextButtonVisibilty = true;
        }
      } else {
        this.isQuizStarted = false;
        if (index !== -1) {
          this.slideVideoList = this.videoList.slice(0, index + 1);
          setTimeout(() => {
            this.$refs.swiperId.swiper.slideTo(index, 0);
          }, 200);
        } else {
          this.isQuizStarted = true;
          this.activeSlideIndex = this.videoList.length;
          this.setQuizData();
        }
      }
    },
    startQuiz() {
      this.isQuizStarted = true;
      this.activeSlideIndex = this.videoList.length;
      this.answerResponse = null;
      this.nextButtonVisibilty = false;
      this.setQuizData();
      window.scrollTo(0, 0);
    },
    setQuizData(priorty) {
      this.selectedAnswer = null;
      Entrepreneurship.getQuizData({
        contentTypeId:
          this.finalQuizId > 0 ? +this.currentCategory.parentId : +this.currentCategory.id,
        priorty,
      }).then(res => {
        if (!res?.data?.Data?.detail) return;
        this.quizData = res.data.Data.detail;
      });
      if (priorty) {
        this.submitedAnswer = false;
      }
    },
    async checkFinalQuiz() {
      await Entrepreneurship.checkFinalQuiz(
        this.finalQuizId > 0 ? +this.currentCategory.parentId : +this.currentCategory.id,
      ).then(res => {
        if (!res?.data?.Data) return;

        this.finishQuizModel = res?.data?.Data;
        this.academyPopupImage = res?.data?.Data?.image;
        this.finalQuizId = this.finishQuizModel.finalQuizId;
        this.isSuccessCertificate = res?.data?.Data?.isSuccessCertificate;
        this.isVisibleModulePopup = true;
      });
    },
    onProgress(eventData, isEnded) {
      let log = {
        percent: eventData.percent,
        stopTime: eventData.position,
        isEnded: eventData.viewStatus,
      };
      this.moduleData.videos[this.activeSlideIndex].log = log;
      if (isEnded) {
        this.setCurrentState(true);
      }
    },
    selectOption(id) {
      this.selectedAnswer = id;
    },
    optionClass(id) {
      let optClass = '';
      if (!this.submitedAnswer) {
        this.selectedAnswer === id ? (optClass = 'active') : (optClass = '');
      } else {
        this.answerResponse && this.answerResponse.trueOptionId === id
          ? (optClass = 'disabled true')
          : this.answerResponse &&
            this.answerResponse.trueOptionId !== id &&
            id === this.selectedAnswer
          ? (optClass = 'disabled false')
          : (optClass = 'disabled');
      }
      return optClass;
    },
    async finishQuiz(request) {
      await Entrepreneurship.finishQuiz(request);
    },
    answerQuiz(request) {
      Entrepreneurship.answerQuiz(request).then(res => {
        if (!res?.data?.Data) return;
        this.answerResponse = res.data.Data;
      });
    },
    async onSubmit() {
      if (!this.answerResponse) {
        this.submitedAnswer = true;
        this.answerQuiz({
          surveyId: this.quizData.surveyId,
          questionId: this.quizData.nextQuestion.questionId,
          optionId: this.selectedAnswer,
          surveyTakenId: this.quizData.surveyTakenId,
        });
      } else if (!this.quizData.isLastQuestion) {
        this.answerResponse = null;
        this.setQuizData(this.quizData.nextQuestion.priorty);
      } else {
        await this.finishQuiz({
          surveyId: this.quizData.surveyId,
          surveyTakenId: this.quizData.surveyTakenId,
        });
        await this.checkFinalQuiz();
        this.submitedAnswer = false;
      }
    },
    toSlide(i) {
      if (this.activeSlideIndex === this.videoList.length) {
        this.activeSlideIndex = i;
      }
      if (this.isQuizStarted) {
        this.slideVideoList = this.videoList;
        this.isQuizStarted = false;
        setTimeout(() => {
          this.$refs.swiperId.swiper.slideTo(i, 0);
          window.scrollTo(0, 0);
        }, 2000);
      } else {
        this.$refs.swiperId.swiper.slideTo(i, 0);
        window.scrollTo(0, 0);
      }
      const index = this.videoList.findIndex(i => !i.log || i.log.status === '1');
      if (index === -1 && i === this.videoList.length - 1) {
        this.nextButtonVisibilty = true;
      } else if (i !== this.videoList.length - 1) {
        this.nextButtonVisibilty = false;
      }
    },
    changeSwiperIndex() {
      const index = this.videoList.findIndex(i => !i.log || i.log.status === '1');
      this.activeSlideIndex = this.$refs.swiperId.swiper.activeIndex;
      if (index === -1 && this.activeSlideIndex === this.videoList.length - 1) {
        this.nextButtonVisibilty = true;
      } else if (this.activeSlideIndex !== this.videoList.length - 1) {
        this.nextButtonVisibilty = false;
      }
    },
    setDefaultState() {
      this.moduleData = {};
      this.currentCategory = {};
      this.slideVideoList = [];
      this.videoList = [];
      this.activeSlideIndex = 0;
      this.quizData = {};
      this.nextButtonVisibilty = false;
      this.isQuizStarted = false;
      this.answerResponse = null;
      this.selectedAnswer = null;
      this.submitedAnswer = false;
      this.finishQuizModel = {};
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  padding-left: 20px;
  background-color: transparent;
}
.final-quiz-header {
  margin: 75px 0 75px 10%;
  font-size: 40px;
}
.take-final-exam {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
}
.headline {
  font-size: 30px;
}
.video-banner {
  border-bottom: 1px solid #b4c2d3;
  position: relative;
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  .video-frame {
    position: relative;
    display: flex;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
  }
}
.other-videos {
  margin-top: 59px;
  margin-bottom: 100px;
  .video-card-wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: 20px;
    .card {
      max-height: 331px;
      border: 1px solid #b4c2d3;
      background-color: #fff;
      cursor: pointer;
      &.passive {
        pointer-events: none;
        .card-inner {
          background-color: #f6f5ee;
          .card-img {
            filter: grayscale(1);
          }
          .card-content {
            color: #5a5951;
          }
        }
      }
      .card-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
      }
    }
  }
}
.description {
  line-height: 1.42;
  padding-top: 30px;
  padding-bottom: 30px;
}
.link-btns {
  max-width: 600px;
  margin: 50px auto;
  .link-btn {
    max-width: 600px;
    margin-bottom: 20px;
    border: 1px solid #1e8fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #1e8fff;
    &:nth-child(1),
    &:nth-child(2) {
      background-image: linear-gradient(
        207deg,
        rgba(30, 143, 255, 1) -14%,
        rgba(30, 143, 255, 1) 88%
      );
    }
  }
}

.card-content {
  padding: 20px;
}
.card-img {
  width: 100%;
  filter: none;
}
.card-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 2px;
}
.card-desc {
  line-height: 1.43;
  color: #79838e;
  margin-bottom: 3px;
}
.card-step-bar {
  margin: 0 auto;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-bar {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-right: 45px;
  .step {
    height: 3px;
    background-color: #ebebeb;
    position: relative;
    flex: 1;
    &.active {
      background-color: #c60000;
      cursor: pointer;
      &:after {
        border-color: #c60000;
        background-color: #c60000;
        color: #fff;
      }
    }
    &.current {
      cursor: pointer;
      &:before {
        content: '';
        border: 2px solid;
        border-color: #fff !important;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2px;
        width: 45px;
        height: 45px;
        z-index: 1;
      }
      &:after {
        width: 50px;
        height: 50px;
        border-radius: 24px;
        background-color: #c60000;
        border-color: #c60000;
      }
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 45px;
      height: 45px;
      border: 3px solid #ebebeb;
      border-radius: 25px;
      background-color: #fff;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-weight: 500;
      color: #ebebeb;
    }
    &:nth-child(1) {
      &:after {
        content: '1';
      }
    }
    &:nth-child(2) {
      &:after {
        content: '2';
      }
    }
    &:nth-child(3) {
      &:after {
        content: '3';
      }
    }
    &:nth-child(4) {
      &:after {
        content: '4';
      }
    }
    &:nth-child(5) {
      &:after {
        content: '5';
      }
    }
    &:nth-child(6) {
      &:after {
        content: '6';
      }
    }
    &:last-child {
      &:after {
        content: 'Quiz';
        width: 88px;
        right: -44px;
      }
      &.current {
        &:before {
          content: '';
          border: 2px solid;
          border-color: #fff !important;
          background-color: transparent;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2px;
          width: 88px;
          height: 43px;
          z-index: 1;
          right: -42px;
          border-radius: 22px;
        }
        &:after {
          width: 92px;
          height: 46px;
        }
      }
    }
  }
}
.step-number {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;
  color: #1e8fff;
  margin-left: 12px;
}

.quiz-wrapper {
  padding-bottom: 30px;
  margin: 0 auto;
}
.quiz {
  padding-bottom: 10px;

  &-img {
    width: 100%;
  }
  .question {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    span {
      margin-left: 20px;
      margin-right: 5px;
      font-weight: 500;
    }
  }
  .option {
    display: block;
    width: 100%;
    background-color: #fff;
    min-height: 60px;
    border-radius: 4px;
    border: solid 1px #79838e;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
    &.active {
      color: #fff;
      background-color: #79838e;
      border-color: #79838e;
    }
    &.true {
      color: #fff;
      background-color: #6dd400;
      border-color: #6dd400;
    }
    &.false {
      color: #fff;
      background-color: #e5472d;
      border-color: #e5472d;
    }
    &.disabled {
      pointer-events: none;
    }
  }
}
.btn-answer {
  font-weight: 500;
  height: 60px !important;
  font-size: 18px;
  background: #c60000;
}
.quiz-arrow-btn {
  position: absolute;
  top: 45%;
  width: 27px;
  height: 44px;
  margin-top: 5px;
  z-index: 8;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
  display: none;
  &.active {
    display: block;
  }
}
</style>
