<script>
import { FLEX_DIRECTIONS } from '@/constants/flex.constants.js';
import { variationValidator } from '@/utils/validators/propValidators.js';

export default {
  name: 'VueListView',
  props: {
    dir: {
      type: String,
      default: FLEX_DIRECTIONS.row,
      validator: value => variationValidator(FLEX_DIRECTIONS, value),
    },
    isInline: {
      type: Boolean,
      default: true,
    },
  },

  render: function(h) {
    const listViewClasses = [this.$style['list-view'], this.$style[`direction-${this.$props.dir}`]];

    if (this.$props.isInline) {
      listViewClasses.push(this.$style.inline);
    }

    let items = this.$slots.default.map(l => h('li', null, [l]));
    return h('ul', { class: listViewClasses }, items);
  },
};
</script>

<style module lang="scss">
.list-view {
  display: flex;
  align-self: center;
  width: 100%;
  list-style: none;
  overflow: auto;
  flex-wrap: wrap;
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  flex-direction: row;
}

.direction-column-reverse {
  flex-direction: column-reverse;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.inline {
  flex-wrap: nowrap;
}
</style>
